const rangeInput = document.querySelectorAll(".filter-range__range input");
const priceInput = document.querySelectorAll(".filter-range__price input");
const range = document.querySelector(".filter-range__slider-progress");
let priceGap = 300;

rangeInput.forEach((input) => {
  rangeInputChange(input);
  input.addEventListener("input", () => {
    rangeInputChange(input);
  });
});

function rangeInputChange(input) {
  let minVal = parseInt(rangeInput[0].value),
    maxVal = parseInt(rangeInput[1].value);
  if (maxVal - minVal < priceGap) {
    if (input.className === "filter-range__range-min") {
      rangeInput[0].value = maxVal - priceGap;
    } else {
      rangeInput[1].value = minVal + priceGap;
    }
  } else {
    priceInput[0].value = minVal;
    priceInput[1].value = maxVal;
    range.style.left = (minVal / rangeInput[0].max) * 100 + "%";
    range.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + "%";
  }
}
