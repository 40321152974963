const submitBtn = document.querySelector("#search-submit")
const search = document.querySelector("#search-input")
const clearBtn = document.querySelector("#search-clear")
const closeBtn = document.querySelector("#search-close")
const searchInput = document.querySelector("#search-input-wrapper")
const list = document.querySelector(".header__block_menu")
const openBtn = document.querySelector(".search-btn-open")
const requests = document.querySelector(".search-input__requests")
const headerBar = document.querySelector(".header-bar")
const headerCallback = document.querySelector(".header-callback")
const headerGrid = document.querySelector(".header__grid")
const searchOverlay = document.querySelector(".search-input-overlay")
const header = document.querySelector(".header")
const scroll = document.querySelector(".scroll")
const menuBtn = document.querySelector(".menu-btn")
const catalogBtn = document.querySelector("#catalog-btn")
const menu = document.querySelector(".mega-menu")
const menuOverlay = document.querySelector(".mega-menu-overlay")
const closeMenuBtns = document.querySelectorAll(".close-menu-btn")
const introListItem = document.querySelectorAll(".intro-list__item")
const introListImg = document.querySelectorAll(".intro__img")
import { tabs } from "./tabs"

const popularReqTemplate = `
  <div class="search-input__requests active">
    <p>Популярные запросы</p>
    <div class="search-input__requests_list">
      <a href="#" class="search-input__requests_item">Труба профильная 200x200x9мм</a>
      <a href="#" class="search-input__requests_item">Плюминиевый прокат</a>
      <a href="#" class="search-input__requests_item">Титановый лист</a>
      <a href="#" class="search-input__requests_item">Медная проволока</a>
      <a href="#" class="search-input__requests_item">Алюминиевые прутки</a>
      <a href="#" class="search-input__requests_item">Балка</a>
    </div>
  </div>
`

const reqTemplate = `
  <div class="search-input__requests active">
    <div class="search-input__requests_list">
      <a class="search-input__requests_item" href="#">
        <img src="../assets/images/no-product.png" alt="">
        Лист нержавеющий х/к холоднокатанный 0.5х30, марка AISI 430 (08Х17)
      </a>
      <a class="search-input__requests_item" href="#">
        <img src="../assets/images/product-1.png" alt="">
        Лист нержавеющий х/к холоднокатанный 0.5х30, марка AISI 430 (08Х17)
      </a>
      <a class="search-input__requests_item" href="#">
        <img src="../assets/images/no-product.png" alt="">
        Лист нержавеющий х/к холоднокатанный 0.5х30, марка AISI 430 (08Х17)
      </a>
      <a class="search-input__requests_item" href="#">
        <img src="../assets/images/product-1.png" alt="">
        Лист нержавеющий х/к холоднокатанный 0.5х30, марка AISI 430 (08Х17)
      </a>
      <a class="search-input__requests_item" href="#">
        <img src="../assets/images/no-product.png" alt="">
        Лист нержавеющий х/к холоднокатанный 0.5х30, марка AISI 430 (08Х17)
      </a>
      <a class="search-input__requests_item" href="#">
        <img src="../assets/images/product-1.png" alt="">
        Лист нержавеющий х/к холоднокатанный 0.5х30, марка AISI 430 (08Х17)
      </a>
    </div>
    <div class="search-input__requests_bottom">
      <a class="btn btn--blue search-input__requests_btn" href="#" id="btn-search-input__requests_btn">
        Все результаты
        <svg class="icon icon-arrow-right" width="24" height="24">
          <use xlink:href="#arrow-right"></use>
        </svg>
      </a>
    </div>
  </div>
`

const toggleSearchInput = () => {
	const isMobile = window.innerWidth <= 1023

	searchInput.classList.toggle("active", true)
	list.classList.toggle("hidden", true)
	openBtn.classList.toggle("hidden", true)
	searchInput.insertAdjacentHTML("beforeend", popularReqTemplate)

	if (isMobile) {
		headerBar?.classList.toggle("hidden")
		headerCallback?.classList.toggle("hidden")
		headerGrid?.classList.toggle("active")
	}

	search.focus()
}

openBtn.addEventListener("click", toggleSearchInput)

const closeSearchInput = () => {
	searchInput.classList.remove("active")
	list.classList.remove("hidden")
	openBtn.classList.remove("hidden")
	if (requests) {
		requests.parentNode.removeChild(requests)
	}

	headerBar?.classList.remove("hidden")
	headerCallback?.classList.remove("hidden")
	headerGrid?.classList.remove("active")
	searchOverlay.classList.remove("active")
}

closeBtn.addEventListener("click", closeSearchInput)
searchOverlay.addEventListener("click", closeSearchInput)

search.addEventListener("input", e => {
	clearBtn.classList.toggle("active", e.target.value !== "")
})

clearBtn.addEventListener("click", () => {
	clearBtn.classList.remove("active")
	search.value = ""
	search.focus()
})

introListItem.forEach((item, index) => {
	item.addEventListener("click", () => {
		introListItem.forEach(el => el.classList.remove("active"))
		introListImg.forEach((img, i) => {
			img.classList.toggle("active", i === index)
		})
		item.classList.add("active")
	})
})

if (scroll) {
	scroll.addEventListener("click", () => {
		window.scrollTo({ top: header.offsetHeight, behavior: "smooth" })
	})
}

const toggleMenu = () => {
	menu.classList.toggle("active")
	menuOverlay.classList.toggle("active")
	document.body.classList.toggle("no-scroll")
}
const closeMenu = () => {
	menu.classList.remove("active")
	menuOverlay.classList.remove("active")
	document.body.classList.remove("no-scroll")
}

menuBtn.addEventListener("click", toggleMenu)
catalogBtn.addEventListener("click", () => {
	toggleMenu()
	MicroModal.close("modal-menu")
})

menuOverlay.addEventListener("click", () => {
	toggleMenu()
})

closeMenuBtns.forEach(el => {
	el.addEventListener("click", toggleMenu)
})

document.querySelector(".mega-menu__header_back.close-menu-btn")?.addEventListener("click", () => {
	MicroModal.show("modal-menu")
})

document.addEventListener("keydown", event => {
	if (event.key === "Escape") {
		closeSearchInput()
		closeMenu()
	}
})

document.addEventListener("mouseup", function (e) {
	if (!search.contains(e.target)) {
		closeSearchInput()
	}
})

const templateLeftItem = (id, name) => {
	return `
			<button class="mega-menu__left_item ${id === 1 ? "active" : ""}" data-tab="${id}">
				<p>${name}</p>
				<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9 18.5L15 12.5L9 6.5" stroke="#FF5C1B" stroke-width="1.5"></path>
				</svg>
			</button>
		`
}

const templateRightBlock = (name, items) => {
	return `
			<div class="mega-menu__right_block">
				<div class="mega-menu__right_category">
					<p>${name}</p>
				</div>
				<div class="mega-menu__right_list">
					${items.map(item => `<a href="${item.link}">${item.name}</a>`).join("")}
				</div>
			</div>
		`
}
const templateRightItem = (id, items, hidden) => {
	return `
			<div class="mega-menu__right_grid ${hidden ? "hidden" : ""}" data-tab-content="${id}">
				${items.map(item => templateRightBlock(item.name, item.items)).join("")}
			</div>
		`
}

async function menuRender(toggle) {
	const response = await fetch("../assets/static/dataMenu.json")
	const data = await response.json()

	const listLeftMenu = document?.querySelector(".mega-menu__left_list")
	const listRightMenu = document?.querySelector(".mega-menu__right")

	listLeftMenu.innerHTML = ""
	listRightMenu.innerHTML = ""

	data[toggle].forEach((item, index) => {
		listLeftMenu.insertAdjacentHTML("beforeend", templateLeftItem(item.id, item.name))
		listRightMenu.insertAdjacentHTML("beforeend", templateRightItem(item.id, item.items, index > 0))
	})

	tabs(".mega-menu__inner")
}

menuRender("material")

function Toggler(name) {
	if (name == "material") {
		return "type"
	} else if (name == "type") {
		return "material"
	} else {
		return "material"
	}
}

function tabsToggler(containerSelector) {
	const tabContainer = document.querySelector(containerSelector)
	const tabToggler = tabContainer?.querySelectorAll("[data-tab-toggle]")
	const tabContentsToggler = tabContainer?.querySelectorAll("[data-tab-toggle-content]")
	let stateToggler = Toggler()

	tabToggler?.forEach(toggler => {
		toggler.addEventListener("click", () => {
			stateToggler = Toggler(stateToggler)

			if (stateToggler == "type") {
				toggler?.querySelector(".toggler__toggle").classList.add("right")
			} else {
				toggler?.querySelector(".toggler__toggle").classList.remove("right")
			}
			menuRender(stateToggler)
		})
	})
}

tabsToggler(".mega-menu")
