import "./index.scss"

import "./js/swiper"
import "./js/tabs"
import "./js/header"
import "./js/InputMask"
import "./js/map"
import "./js/inputFile"
import MicroModal from "micromodal"
MicroModal.init()

import "./components/scripts"
import "./elements/scripts"
import "./modules/scripts"
