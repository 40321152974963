const map = document.getElementById("map")

if (map) {
	function placemarkPosition(map, cords) {
		var geoCenter
		if (window.innerWidth > 786) {
			var pixelCenter = map.getGlobalPixelCenter(cords)
			pixelCenter = [pixelCenter[0] - 300, pixelCenter[1]]
			geoCenter = map.options.get("projection").fromGlobalPixels(pixelCenter, map.getZoom())
		} else {
			geoCenter = cords
		}
		return geoCenter
	}
	ymaps.ready(init)
	function init() {
		const cords = map.dataset.cords.split(",")
		var myMap = new ymaps.Map("map", {
			center: cords,
			zoom: 16,
			controls: ["zoomControl"],
		})
		myMap.setCenter(placemarkPosition(myMap, cords))
		var myPlacemark = new ymaps.Placemark(
			cords,
			{},
			{
				iconLayout: "default#image",
				iconImageHref: "../assets/images/placemark-map.svg",
				iconImageSize: [40, 40],
				// iconImageOffset: [0, 0]
			}
		)
		myMap.geoObjects.add(myPlacemark)
		// myMap.behaviors.disable(["scrollZoom"])
		myMap.controls.remove("zoomControl")
	}
}
