const setCookie = (cname, cvalue, exdays) => {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
};

if (!getCookie("cookiePolicyAccepted")) {
  const cookieBlock = document.querySelector(".cookie-policy");
  const cookieAccept = cookieBlock.querySelector(".cookie-policy__btn");
  cookieBlock.classList.add("open");

  cookieAccept.addEventListener("click", () => {
    setCookie("cookiePolicyAccepted", "true", 31);
    cookieBlock.classList.remove("open");
  });
}
