document.addEventListener("DOMContentLoaded", () => {
  const catalogToggler = document.querySelectorAll(".catalog-toggler");

  catalogToggler.forEach((toggler) => {
    const togglerWrapper = toggler.querySelector(".catalog-toggler__wrapper");
    const toggles = toggler.querySelectorAll(".catalog-toggler__title");
    const toggle = toggler.querySelector(".catalog-toggler__toggle");

    // Функция для скрытия/показа элементов с атрибутом data-catalog-element
    function toggleCatalogElements(isRight) {
      const catalogElements = document.querySelectorAll(
        "[data-catalog-element]"
      );
      catalogElements.forEach((element) => {
        const elementType = element.getAttribute("data-catalog-element");
        if (
          (isRight && elementType === "material") ||
          (!isRight && elementType === "type")
        ) {
          element.classList.remove("d-none");
        } else {
          element.classList.add("d-none");
        }
      });
    }

    // При загрузке страницы проверяем состояние переключателя и скрываем соответствующие элементы
    const isRightInitially = toggle.classList.contains("right");
    toggleCatalogElements(isRightInitially);

    toggles.forEach((toggler) => {
      toggler.addEventListener("click", () => {
        const toggleType = toggler.getAttribute("data-catalog-toggle");

        toggles.forEach((t) => t.classList.remove("active"));
        toggler.classList.add("active");

        if (toggleType === "type") {
          toggle.classList.add("right");
        } else {
          toggle.classList.remove("right");
        }

        toggleCatalogElements(toggleType === "type");
      });
    });

    togglerWrapper.addEventListener("click", () => {
      const isRight = toggle.classList.toggle("right");
      toggles.forEach((t) => t.classList.remove("active"));
      document
        .querySelector(
          `[data-catalog-toggle="${isRight ? "type" : "material"}"]`
        )
        .classList.add("active");

      toggleCatalogElements(isRight);
    });
  });
});
