document.addEventListener("DOMContentLoaded", function () {
	const tagsContainer = document.querySelector(".filter-tags")
	const tagWrapper = tagsContainer?.querySelector(".filter-tags__wrapper")
	const tags = tagsContainer?.querySelectorAll(".filter-tags__item")
	const moreButton = tagsContainer?.querySelector(".filter-tags__item-more")
	const hideButton = tagsContainer?.querySelector(".filter-tags__item-hide")
	const countHiddenTags = tagsContainer?.querySelector(".filter-tags__count")

	function updateTagsWidth() {
		if (tags) {
			const wrapperWidth = tagWrapper?.offsetWidth
			let totalWidth = 0
			let visibleTagsCount = 0
			let tagWidth = 0

			tags.forEach(tag => {
				totalWidth += tag.offsetWidth + 10

				if (totalWidth <= wrapperWidth) {
					tagWidth += tag.offsetWidth + 10
					visibleTagsCount++
				}
			})

			// Количество скрытыъ тегов
			// const overflowTagsCount = tags.length - visibleTagsCount;
			// countHiddenTags.innerHTML = overflowTagsCount;
			moreButton.style.left = `${tagWidth}px`

			moreButton.onclick = () => tagsContainer.classList.add("filter-tags--open")
			hideButton.onclick = () => tagsContainer.classList.remove("filter-tags--open")
		}
	}

	updateTagsWidth()
	window.addEventListener("resize", updateTagsWidth)
})
