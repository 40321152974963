document.addEventListener("DOMContentLoaded", function () {
  const filterItems = document.querySelectorAll(".filter__item");
  const filterProduct = document.querySelector(".filter-product");
  const modalFilterContent = document.querySelector("#modal-filter-content");

  filterItems.forEach((item) => {
    const header = item.querySelector(".filter__header");
    const content = item.querySelector(".filter__content");
    const hidden = item.querySelector(".filter__item-hidden");
    const filterList = item.querySelector(".filter-list");
    const filterSearchInput = item.querySelector(".filter-search__input");
    const iconArrow = item.querySelector(".icon-arrow-down");

    // Добавление класса при клике на .filter__item

    if (header && content) {
      header.onclick = () => {
        content.classList.toggle("open");
        iconArrow.classList.toggle("open");
      };
    }
    if (hidden) {
      hidden.onclick = () => {
        if (content) {
          iconArrow.classList.remove("open");
          content.classList.remove("open");
        }
      };
    }

    // Отображение поиска в .filter__item

    item.addEventListener("click", () => {
      if (filterList && filterList.clientHeight >= 250) {
        const filterSearch = item.querySelector(".filter-search");
        if (filterSearch) {
          filterSearch.classList.add("visible");
        }
      }
    });

    // Фильтрация элементов списка при вводе текста в поиск
    let noResults;

    if (filterSearchInput) {
      filterSearchInput.addEventListener("input", () => {
        const searchValue = filterSearchInput.value.toLowerCase();
        const listItems = filterList.querySelectorAll(".filter-list__item");
        let found = false;

        listItems.forEach((listItem) => {
          const label = listItem
            .querySelector("label")
            .textContent.toLowerCase();
          if (label.includes(searchValue)) {
            listItem.style.display = "";
            found = true;
          } else {
            listItem.style.display = "none";
          }
        });
        if (!found) {
          if (!noResults) {
            noResults = document.createElement("div");
            noResults.className = "no-results";
            noResults.textContent = "ничего не найдено";
            filterList.appendChild(noResults);
          }
        } else {
          if (noResults) {
            noResults.remove();
            noResults = null;
          }
        }
      });
    }
  });

  // Перенос фильтра в попап на мобильных устройствах
  if (modalFilterContent) {
    function moveFilter() {
      if (window.innerWidth < 1400) {
        modalFilterContent.appendChild(filterProduct);
      } else {
        document
          .querySelector(".template-grid__aside-block")
          .appendChild(filterProduct);
      }
    }
    moveFilter();
    window.addEventListener("resize", moveFilter);
  }
});
