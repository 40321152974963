const fileInputs = document.querySelectorAll(".file-input")

fileInputs.forEach(fileInput => {
	const inputFile = fileInput.querySelector('input[type="file"]')
	const inputFileText = fileInput.querySelector("#input-file-text")
	const filesContainer = fileInput.querySelector(".file-input__files")

	inputFile.addEventListener("change", () => {
		const selectedFiles = inputFile.files

		if (selectedFiles.length > 0) {
			fileInput.classList.remove("error")
			if (inputFileText) {
				inputFileText.innerText = "Не более 25 Мб"
			}

			for (let i = 0; i < selectedFiles.length; i++) {
				const file = selectedFiles[i]
				if (file.size > 25 * 1024 * 1024) {
					fileInput.classList.add("error")
					if (inputFileText) {
						inputFileText.innerText = "Размер файла превышает 25 Мб"
					}
					break
				} else {
					const fileElement = document.createElement("div")
					fileElement.classList.add("file-input__file")
					fileElement.innerHTML = `
                <p>${file.name}</p>
                <button type="button" class="file-input__del">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2Multiplier000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5428 21.0038H8.45698C7.28078 21.0038 6.30288 20.0982 6.21267 18.9255L5.24707 6.37268H18.7527L17.7871 18.9255C17.6969 20.0982 16.719 21.0038 15.5428 21.0038V21.0038Z" stroke="#7E8185" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20.0032 6.37264H3.99658" stroke="#7E8185" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.1865 2.99622H14.8138C15.4354 2.99622 15.9393 3.50011 15.9393 4.12168V6.37262H8.06104V4.12168C8.06104 3.82319 8.17961 3.53692 8.39068 3.32586C8.60174 3.11479 8.88801 2.99622 9.1865 2.99622Z" stroke="#7E8185" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M9.99951 17.0021H14.0012" stroke="#7E8185" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </button>
            `

					fileElement.setAttribute("data-file", file)

					filesContainer.appendChild(fileElement)

					const deleteButton = fileElement.querySelector(".file-input__del")
					deleteButton.addEventListener("click", () => {
						const fileToRemove = fileElement.getAttribute("data-file")

						filesContainer.removeChild(fileElement)

						// const updatedSelectedFiles = selectedFiles.filter(selectedFile => selectedFile !== fileToRemove)
						// inputFile.files = updatedSelectedFiles
					})
				}
			}
		}
	})
})
