document.addEventListener("DOMContentLoaded", function () {
  const productCounters = document.querySelectorAll(".product-counter");

  productCounters.forEach((counter) => {
    const decrementButton = counter.querySelector(
      ".product-counter__decrement"
    );
    const incrementButton = counter.querySelector(
      ".product-counter__increment"
    );
    const productCountInput = counter.querySelector(".product-counter__value");

    decrementButton.onclick = () => {
      let currentValue = parseInt(productCountInput.value);
      if (currentValue > 0) {
        productCountInput.value = currentValue - 1;
      }
    };

    incrementButton.onclick = () => {
      let currentValue = parseInt(productCountInput.value);
      productCountInput.value = currentValue + 1;
    };
  });
});
