document.addEventListener("DOMContentLoaded", function () {
  const rows = document.querySelectorAll("table.in-stock tbody tr");

  rows.forEach((row) => {
    const input = row.querySelector(".in-stock__input");
    const priceElement = row.querySelector(".in-stock__price");
    const notSelectedMsg = row.querySelector(".in-stock__not-selected");
    const selectedMsg = row.querySelector(".in-stock__selected");
    const button = row.querySelector(".btn--blue");
    const priceFor = row.querySelector(".in-stock__price-for");

    function updatePlaceholder() {
      if (input) {
        if (window.innerWidth < 450) {
          input.placeholder = "Кол-во";
        } else {
          input.placeholder = "Количество";
        }
      }
      if (notSelectedMsg) {
        if (window.innerWidth < 768) {
          notSelectedMsg.innerHTML = "Укажите кол-во";
        } else {
          notSelectedMsg.innerHTML = "Укажите <br/> кол-во";
        }
      }
    }

    updatePlaceholder();
    window.addEventListener("resize", updatePlaceholder);

    if (
      input &&
      priceElement &&
      notSelectedMsg &&
      selectedMsg &&
      button &&
      priceFor
    ) {
      input.addEventListener("input", function () {
        const quantity = parseFloat(input.value);
        const price = parseFloat(
          priceElement.textContent.replace("₽", "").replace(/\s/g, "")
        );

        if (!isNaN(quantity) && quantity > 0) {
          const total = (quantity * price).toFixed(2);
          selectedMsg.textContent = `${total} ₽`;
          notSelectedMsg.style.display = "none";
          selectedMsg.style.display = "block";
          button.disabled = false;
          button.style.cursor = "pointer";
          button.style.opacity = "1";
        } else {
          selectedMsg.style.display = "none";
          notSelectedMsg.style.display = "block";
          button.disabled = true;
          button.style.cursor = "not-allowed";
          button.style.opacity = "0.5";
        }
      });
    }
  });

  const tabs = document.querySelectorAll(".in-stock__tab");
  tabs.forEach((tab) => {
    tab.addEventListener("click", function () {
      tabs.forEach((t) => t.classList.remove("active"));
      this.classList.add("active");
      rows.forEach((row) => {
        const priceFor = row.querySelector(".in-stock__price-for");
        if (priceFor) {
          priceFor.textContent = this.textContent;
        }
      });
    });
  });
});
