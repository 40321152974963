import Swiper from "swiper"
import { Navigation, Pagination, Grid } from "swiper/modules"

Swiper.use([Navigation, Pagination, Grid])

// Наша команда
let OurTeam = new Swiper("#swiper-our-team", {
	spaceBetween: 48,
	navigation: {
		nextEl: ".section-swiper__nav-next",
		prevEl: ".section-swiper__nav-prev",
	},
	pagination: {
		el: ".section-swiper__nav-pagination",
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		601: {
			slidesPerView: 2,
		},
		769: {
			slidesPerView: 3,
		},
		1400: {
			slidesPerView: 4,
		},
	},
})

// Наш офис
let OurOffice = new Swiper("#swiper-our-office", {
	spaceBetween: 48,
	navigation: {
		nextEl: ".section-swiper__nav-next",
		prevEl: ".section-swiper__nav-prev",
	},
	pagination: {
		el: ".section-swiper__nav-pagination",
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		601: {
			slidesPerView: 2,
		},
		1200: {
			slidesPerView: 3,
		},
	},
})

// Способы оплаты
let PaymentMethod = new Swiper("#swiper-payment-method", {
	spaceBetween: 48,
	navigation: {
		nextEl: ".section-swiper__nav-next",
		prevEl: ".section-swiper__nav-prev",
	},
	pagination: {
		el: ".section-swiper__nav-pagination",
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		601: {
			slidesPerView: 2,
		},
		1200: {
			slidesPerView: 3,
		},
	},
})

// Транспортные компании
let DeliveryCompany = new Swiper("#swiper-delivery-company", {
	spaceBetween: 48,
	navigation: {
		nextEl: ".section-swiper__nav-next",
		prevEl: ".section-swiper__nav-prev",
	},
	pagination: {
		el: ".section-swiper__nav-pagination",
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		601: {
			slidesPerView: 2,
		},
		1024: {
			slidesPerView: 3,
		},
	},
})

// Клиенты доверяют нам
let OurClient = new Swiper("#swiper-our-clietns", {
	spaceBetween: 48,
	navigation: {
		nextEl: ".section-swiper__nav-next",
		prevEl: ".section-swiper__nav-prev",
	},
	pagination: {
		el: ".section-swiper__nav-pagination",
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		601: {
			slidesPerView: 2,
		},
		1024: {
			slidesPerView: 3,
		},
		1400: {
			slidesPerView: 4,
		},
	},
})

// Отзывы
let SwiperReviews = new Swiper("#swiper-reviews", {
	spaceBetween: 48,
	navigation: {
		nextEl: ".section-swiper__nav-next",
		prevEl: ".section-swiper__nav-prev",
	},
	pagination: {
		el: ".section-swiper__nav-pagination",
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		601: {
			slidesPerView: 2,
		},
		1200: {
			slidesPerView: 3,
		},
	},
})
let SwiperVacancies = new Swiper("#swiper-vacansies", {
	spaceBetween: 24,
	navigation: {
		nextEl: ".section-swiper__nav-next",
		prevEl: ".section-swiper__nav-prev",
	},
	pagination: {
		el: ".section-swiper__nav-pagination",
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		601: {
			slidesPerView: 2,
		},
		1200: {
			slidesPerView: 4,
		},
	},
})
if (window.innerWidth > 768) {
	for (let i = 0; i < 20; i++) {
		new Swiper(`#work__slider-${i + 1}`, {
			slidesPerView: 1,
			navigation: {
				nextEl: `.work__control_next-${i + 1}`,
				prevEl: `.work__control_prev-${i + 1}`,
			},
		})
	}
}

let servicesSlider = new Swiper("#swiper-services-slider", {
	spaceBetween: 24,
	navigation: {
		nextEl: ".section-swiper__nav-next",
		prevEl: ".section-swiper__nav-prev",
	},
	pagination: {
		el: ".section-swiper__nav-pagination",
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		601: {
			slidesPerView: 2,
		},
		1000: {
			slidesPerView: 3,
		},
		1200: {
			slidesPerView: 4,
		},
	},
})
