document.addEventListener("DOMContentLoaded", function () {
  // Функция для закрытия всех открытых модалок
  function closeAllModals() {
    const openModals = document.querySelectorAll(".modal.is-open");
    openModals.forEach(function (modal) {
      modal.classList.remove("is-open");
      const modalId = modal.getAttribute("id");
      if (modalId) {
        MicroModal.close(modalId);
      }
    });
  }

  // Обработчики для всех элементов, открывающих модалки
  const modalTriggers = document.querySelectorAll("[data-micromodal-trigger]");
  modalTriggers.forEach(function (trigger) {
    trigger.addEventListener("click", function (event) {
      event.preventDefault();
      closeAllModals();
      const targetModal = trigger.getAttribute("data-micromodal-trigger");
      MicroModal.show(targetModal);
    });
  });

  // Обработчик для форм
  const forms = document.querySelectorAll(".not-verified");

  forms.forEach((form) => {
    const inputs = form.querySelectorAll("input");
    const modalId = "modal-success";

    form.addEventListener("submit", function (event) {
      event.preventDefault();

      let allFilled = true;

      inputs.forEach(function (input) {
        if (input.hasAttribute("required") && !input.value.trim()) {
          allFilled = false;
        }
      });

      closeAllModals();

      if (allFilled) {
        MicroModal.show(modalId);
        setTimeout(function () {
          MicroModal.close(modalId);
        }, 5000);
      }
    });
  });
});
