const phoneInputs = document.querySelectorAll('[name="phone"]');

phoneInputs?.forEach((inp) => {
  inp.addEventListener("keydown", (e) => {
    if (e.key == "Backspace" && inp.value.replace(/\D/g, "").length == 1) {
      inp.value = "";
    }
  });

  inp.addEventListener("input", (e) => {
    let inputNumbersValue = inp.value.replace(/\D/g, "");
    const selectionStart = inp.selectionStart;
    let formattedInputValue = "";

    if (!inputNumbersValue) {
      return (inp.value = "");
    }

    if (inp.value.length != selectionStart) {
      if (e.data && /\D/g.test(e.data)) {
        inp.value = inputNumbersValue;
      }
      return;
    }

    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
      if (inputNumbersValue[0] == "9")
        inputNumbersValue = "7" + inputNumbersValue;
      const firstSymbols = inputNumbersValue[0] == "8" ? "8" : "+7";
      formattedInputValue = inp.value = firstSymbols + " ";
      if (inputNumbersValue.length > 1) {
        formattedInputValue += "(" + inputNumbersValue.slice(1, 4);
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += ") " + inputNumbersValue.slice(4, 7);
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += "-" + inputNumbersValue.slice(7, 9);
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += "-" + inputNumbersValue.slice(9, 11);
      }
    } else {
      formattedInputValue = "+" + inputNumbersValue.slice(0, 16);
    }
    inp.value = formattedInputValue;
  });

  inp.addEventListener("paste", (e) => {
    let inputNumbersValue = inp.value.replace(/\D/g, "");
    const pasted = e.clipboardData || window.clipboardData;
    if (pasted) {
      const pastedText = pasted.getData("Text");
      if (/\D/g.test(pastedText)) {
        inp.value = inputNumbersValue;
        return;
      }
    }
  });
});
