document.addEventListener("DOMContentLoaded", function () {
  var lists = document.querySelectorAll(".catalog-list");
  lists.forEach(function (list) {
    var listItems = list.querySelectorAll(".catalog-list__item");
    var moreButton = list.querySelector(".catalog-list__more");
    var moreText = moreButton.querySelector(".catalog-list__text");

    if (listItems.length > 15) {
      moreButton.classList.add("visible");
      for (var i = 15; i < listItems.length; i++) {
        listItems[i].classList.add("hidden");
      }

      var hiddenCount = listItems.length - 15;
      var text;

      if (hiddenCount === 1) {
        text = `ещё ${hiddenCount} категория`;
      } else if (hiddenCount > 1 && hiddenCount < 5) {
        text = `ещё ${hiddenCount} категории`;
      } else {
        text = `ещё ${hiddenCount} категорий`;
      }

      moreText.textContent = text;

      moreButton.addEventListener("click", function () {
        for (var i = 15; i < listItems.length; i++) {
          listItems[i].classList.remove("hidden");
        }
        moreButton.classList.remove("visible");
      });
    }
  });
});
