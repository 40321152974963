document.addEventListener("DOMContentLoaded", function () {
  const cityTags = document.querySelectorAll(".city-tags__item");
  const regions = document.querySelectorAll("path[data-city-region]");
  const defaultRegion = "EKB";

  const activeTag = document.querySelector(
    `.city-tags__item[data-region="${defaultRegion}"]`
  );
  const activeRegion = document.querySelector(
    `path[data-city-region="${defaultRegion}"]`
  );
  if (activeTag) activeTag.classList.add("active");
  if (activeRegion) activeRegion.classList.add("active");

  cityTags.forEach((tag) => {
    tag.addEventListener("click", function () {
      cityTags.forEach((tag) => tag.classList.remove("active"));
      regions.forEach((region) => region.classList.remove("active"));

      const regionCode = this.getAttribute("data-region");
      this.classList.add("active");
      document
        .querySelector(`path[data-city-region="${regionCode}"]`)
        .classList.add("active");
    });
  });

  regions.forEach((region) => {
    region.addEventListener("click", function () {
      cityTags.forEach((tag) => tag.classList.remove("active"));
      regions.forEach((region) => region.classList.remove("active"));

      const regionCode = this.getAttribute("data-city-region");
      this.classList.add("active");
      document
        .querySelector(`.city-tags__item[data-region="${regionCode}"]`)
        .classList.add("active");
    });
  });
});
