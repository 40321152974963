const noteBlockRight = document.querySelector(".note-block__text");
const allElements = noteBlockRight?.querySelectorAll("*");
const moreBtn = document.querySelector(".more-btn");
const moreBtnText = moreBtn?.querySelector("span");

let h2h3Count = 0;
let elementsHidden = false;
const hiddenElements = [];

if (noteBlockRight) {
  allElements.forEach((elem) => {
    if (
      elem.tagName.toLowerCase() === "h2" ||
      elem.tagName.toLowerCase() === "h3"
    ) {
      h2h3Count++;
    }

    if (h2h3Count > 2) {
      elem.style.display = "none";
      hiddenElements.push(elem);
      elementsHidden = true;
    }
  });

  if (elementsHidden) {
    moreBtn.classList.add("d-flex");
  }

  function toggleVisibility() {
    const isOpen = moreBtn.classList.contains("open");
    hiddenElements.forEach((elem) => {
      elem.style.display = isOpen ? "none" : "";
    });
    moreBtn.classList.toggle("open");
    moreBtnText.textContent = isOpen ? "Показать больше" : "Скрыть";
  }

  moreBtn.addEventListener("click", toggleVisibility);
}
